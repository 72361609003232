import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './PaymentForm.css';
import axios from "axios";
import XMLParser from "react-xml-parser/xmlParser";

const PaymentForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        emri: '',
        email: '',
        totali: '',
        phone: '',
        group: '',
        sessionID: '',
        paymentId: '',
    });

    const [errors, setErrors] = useState({});

    const getData = async () => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id');

        if (id) {
            try {
                let decoded = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/academy/getacademy/${id}`);
                decoded = decoded.data;
                setFormData({
                    emri: decoded.name,
                    email: decoded.email,
                    totali: decoded.total,
                    phone: decoded.phone,
                    group: decoded.group,
                    sessionID: decoded.sessionId,
                    paymentId: decoded.paymentId,
                });
            } catch (error) {
                console.error('Invalid token', error);
            }
        }
    }

    useEffect(() => {
        getData()
    }, [location.search]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        if (!formData.emri) {
            newErrors.emri = 'Emri është i domosdoshëm';
            valid = false;
        }

        if (!formData.email) {
            newErrors.email = 'Email është i domosdoshëm';
            valid = false;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email është i pavlefshëm';
            valid = false;
        }

        if (!formData.totali) {
            newErrors.totali = 'Totali është i domosdoshëm';
            valid = false;
        } else if (isNaN(formData.totali)) {
            newErrors.totali = 'Totali duhet të jetë një numër';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handlePayment = async () => {
        setLoading(true)
        const queryParams = new URLSearchParams(location.search);

        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/academy/handlepayment`, {
            email: formData.email,
            phone: formData.phone,
            amount: formData.totali,
            id: queryParams.get('id'),
        });

        const orderId = response.data.orderId;
        const sessionId = response.data.sessionId;

        if (validateForm()) {
            window.location.href = `https://3dss2.quipu.de:8445/twpga?ORDERID=${orderId}&SESSIONID=${sessionId}`;
        }
        setLoading(false)
    };

    return (
        <div className="payment-form-container">
            <h2>Informatat e Pageses</h2>
            <form className="payment-form">
                <div className="form-group">
                    <label htmlFor="emri">Emri:</label>
                    <input
                        type="text"
                        id="emri"
                        name="emri"
                        value={formData.emri}
                        onChange={handleInputChange}
                        placeholder="Emri"
                    />
                    {errors.emri && <span className="error">{errors.emri}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                    />
                    {errors.email && <span className="error">{errors.email}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="phone">Numri i Telefonit:</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                        placeholder="Numri i telefonit"
                    />
                    {errors.phone && <span className="error">{errors.phone}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="group">Grupi:</label>
                    <input
                        type="text"
                        id="group"
                        name="group"
                        value={formData.group}
                        onChange={handleInputChange}
                        placeholder="Grupi"
                    />
                    {errors.group && <span className="error">{errors.group}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="totali">Totali:</label>
                    <input
                        type="text"
                        id="totali"
                        name="totali"
                        value={formData.totali}
                        placeholder="Shuma totale"
                        disabled
                    />
                    {errors.totali && <span className="error">{errors.totali}</span>}
                </div>
                <button type="button" className="pay-button" onClick={handlePayment}>
                    {
                        loading ? <span>Procesimi...</span> : <span>Pagesa</span>
                    }</button>
            </form>
        </div>
    );
};

export default PaymentForm;
